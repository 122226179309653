import { useEffect, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

import { IError } from '../utils/get-form-errors';
import { toast } from '../components/hoc/toast';
import { handleFormRTKQueryErrors } from '../utils/get-form-rtk-query-errors';
import useTranslations from './use-translations';

export default function useRtkQueryFormError(
    error: FetchBaseQueryError | SerializedError | IError[] | undefined,
    showErrorToasts?: boolean
) {
    const formikRef = useRef<FormikProps<any>>(null);
    const t = useTranslations('RTKQueryApi');
    const [globalErrorMessage, setGlobalErrorMessage] = useState<string>();
    const [otherErrors, setOtherErrors] = useState<IError[]>();

    useEffect(() => {
        const errors = handleFormRTKQueryErrors(formikRef.current, error, t.errors);
        setGlobalErrorMessage(errors?.globalError);
        setOtherErrors(errors?.otherErrors);
    }, [error, t.errors]);

    useEffect(() => {
        if (!showErrorToasts) return;
        if (otherErrors?.length) {
            otherErrors.forEach((error) => {
                toast(error.content, { type: 'error' });
            });
        }
        if (globalErrorMessage) {
            toast(globalErrorMessage, { type: 'error' });
        }
    }, [globalErrorMessage, otherErrors, showErrorToasts]);

    return { formikRef, globalErrorMessage, otherErrors };
}
