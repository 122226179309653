import React from 'react';

import { container, text } from './activation-panel-mobile.module.scss';

import DesktopIcon from '../../assets/images/svg/desktop.svg';
import useTranslations from '../../hooks/use-translations';

const ActivationPanelMobile = () => {
    const t = useTranslations('ActivationPanelMobile');

    return (
        <div className={container}>
            <DesktopIcon />
            <p className={text}>
                {t.info} <span>{t.infoContinued}</span>.
            </p>
        </div>
    );
};

export default ActivationPanelMobile;
