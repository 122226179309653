import React from 'react';
import { Link } from 'gatsby';

import { container, grid, form, logo, formGrid } from './sign-in.module.scss';

import Logo from '../../assets/images/svg/logo.svg';

import { ISection } from '../../models/section.model';
import { breakpoints } from '../../config/breakpoints';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { useUser } from '../../hooks/use-user';
import { loginForm } from '../../formik/login.form';

import Section from '../hoc/section';
import FormGenerator from '../molecules/form-generator';
import useMediaQuery from '../../hooks/use-media-query';
import ActivationPanelMobile from '../molecules/activation-panel-mobile';

export interface ISignInProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

export default function SignIn({ className, section, TitleTag }: ISignInProps) {
    const { sectionId, css, style } = section;
    const userState = useUser();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(userState.login.errors);
    const isMobile = useMediaQuery(breakpoints.smallLaptop);

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${container}`}
            classes={{ container: grid }}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            <Link className={logo} to={'/'}>
                <Logo />
            </Link>
            {isMobile ? (
                <ActivationPanelMobile />
            ) : (
                <FormGenerator
                    formRef={formikRef}
                    className={form}
                    formClassName={formGrid}
                    formikProps={{ onSubmit: (values) => userState.login.fetch(values) }}
                    name="LoginForm"
                    fields={loginForm}
                    errorMessage={globalErrorMessage}
                    submitLoading={userState.login.isLoading}
                />
            )}
        </Section>
    );
}
