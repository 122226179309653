// extracted by mini-css-extract-plugin
export var bottomContent = "sign-in-module--bottom-content--503c9";
export var bottomWrapper = "sign-in-module--bottom-wrapper--03a71";
export var container = "sign-in-module--container--dc18d";
export var form = "sign-in-module--form--02c78";
export var formGrid = "sign-in-module--form-grid--6b489";
export var grid = "sign-in-module--grid--0882a";
export var logo = "sign-in-module--logo--70496";
export var socialList = "sign-in-module--social-list--2a186";
export var spacer = "sign-in-module--spacer--c39da";
export var subText = "sign-in-module--sub-text--05320";
export var title = "sign-in-module--title--1a8b6";